




































import {
  SfCarousel,
  SfHeading,
  SfLoader,
  SfButton,
} from '@storefront-ui/vue';

import { defineComponent, PropType, onMounted, ref } from '@nuxtjs/composition-api';
import productGetters from '@wemade-vsf/catalog/lib/runtime/product/getters/productGetters';
import { useImage } from '@wemade-vsf/composables';
import { useProduct } from '@wemade-vsf/catalog/lib/runtime/product/composables/useProduct'
import ProductCard from 'components/catalog/Product/ProductCard.vue';

import type { Product } from '@wemade-vsf/catalog/lib/runtime/product/types';

export default defineComponent({
  name: 'ProductsCarousel',
  components: {
    SfCarousel,
    ProductCard,
    SfHeading,
    SfLoader,
    SfButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    skus: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    }
  },
  setup(props) {
    const { loading, getProductList, getProductPath } = useProduct()

    const { getMagentoImage, imageSizes } = useImage();

    const products = ref<Product[]|null>(null)
    onMounted(async () => {
      if (props.skus.length === 0) return
      const baseSearchQuery = {
        filter: {
          sku: {
            in: props.skus
          }
        },
        pageSize: props.skus.length
      }
      const { items } = await getProductList(baseSearchQuery)
      products.value = items.sort((a, b) => {
        return props.skus.indexOf(a.sku) - props.skus.indexOf(b.sku)
      });
    })

    return {
      products,
      loading,
      productGetters,
      getMagentoImage,
      imageSizes,
      getProductPath,
    };
  },
});
