






















































import {
  defineComponent,
  computed,
  PropType,
} from '@nuxtjs/composition-api'
import { Product } from '@wemade-vsf/catalog/lib/runtime/product/types';
import { prepareCardProduct } from '@wemade-vsf/catalog/lib/runtime/product/helpers/prepareCardProduct';
import { useImage } from '@wemade-vsf/composables';
import ProductPrice from 'components/catalog/Product/ProductPrice.vue';
import { useCustomerPricing } from '@wemade-vsf/catalog/lib/runtime/product/composables/useCustomerPricing';
import type { Price } from '@wemade-vsf/catalog/lib/runtime/types';

export default defineComponent({
  name: 'ProductCard',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: [Boolean, Array],
      default: false
    }
  },
  components: {
    ProductPrice,
    ProductCardActions: () => import('components/catalog/Product/ProductCard/Actions.vue'),
    ProductLabels: () => import('components/catalog/Product/ProductLabels.vue')
  },
  setup(props, { emit }) {
    const { imageSizes: { productCard: imageSize } } = useImage();
    const cardProduct = prepareCardProduct(props.product)
    const { title, image, link, nuxtImgConfig, specialPrice, regularPrice, hasPriceRange, price, priceIncludesTax } = cardProduct.cardProps
    const { sku } = cardProduct
    const hasLabels = !!props.product.product_labels?.length
    const availableActions = ['link', ...cardProduct.cardProps.showCartButton ? ['cart'] : []];

    const { getNetPrice } = useCustomerPricing();
    const recommendedNetPrice = computed<Price | null>(() => {
      if (!price) return null;
      
      const priceRaw = getNetPrice(price, props.product.applied_tax_rate);
      return priceRaw;
    });

    const triggerClick = () => {
      emit('clicked:product', props.product.sku)
    }

    return {
      cardProduct,
      image,
      imageSize,
      link,
      nuxtImgConfig,
      regularPrice,
      sku,
      specialPrice,
      title,
      hasPriceRange,
      hasLabels,
      availableActions,
      recommendedNetPrice,
      arePricesIncludingTax: priceIncludesTax,
      triggerClick
    }
  }
})
